import { useSelector } from 'react-redux';
import useSuggestedPrompt from '../../../hooks/chat-room/useSuggestedPrompt';
import useWelcome from '../../../hooks/chat-room/useWelcome';

export default function Welcome() {
  const auth = useSelector((state) => state.auth.data);
  const mentor = useSelector((state) => state.mentors.mentor);
  const prompts = useSelector((state) => state.chat.guidedPrompts);
  const { handleSuggestedPromptSelection } = useSuggestedPrompt();
  const { response } = useWelcome();

  return (
    <div
      react-component="chatListBlock"
      className="w-layout-vflex view-middle-block"
    >
      <img
        className="agentuserprofileimage"
        src={mentor?.settings?.profile_image}
        alt=""
        prop-data-value-src="agentImage"
        sizes="(max-width: 479px) 100vw, 48px"
        prop-attr-names="src"
        loading="lazy"
      />
      <div
        prop-data-value-label="marketing research"
        prop-data-names="label"
        className="text-block-92"
      >
        {mentor.name}
      </div>
      <div
        prop-data-value-label=""
        prop-data-names="label"
        className="text-block-93"
      >
        {mentor?.metadata?.category ?? ''}
        <br />
      </div>
      <div
        prop-data-value-label="Your go-to assistant for cutting-edge marketing insights and analysis, leveraging APIs to Wolfram and other strategic sources."
        prop-data-names="label"
        className="description-and-goal whiten-in-dark-mode"
      >
        {response}
      </div>
      <div className="w-layout-hflex prompts-block">
        {prompts?.slice(0, 4).map((prompt, i) => (
          <div className="w-layout-vflex prompt" key={`${prompt}-${i}`}>
            <div
              prop-data-value-label="What are tips for better productivity?"
              prop-data-names="label"
              className="text-block-94 whiten-in-dark-mode"
              onClick={() => {
                handleSuggestedPromptSelection(prompt);
              }}
            >
              {prompt}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
